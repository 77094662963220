import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import {
  Header,
  Content,
  QuickTitle,
  QuickMenu,
  QuickMenuItem,
} from "./styles";

import defaultConfig from "../../config/default";
import api from "../../services/api";

import Layout from "../../components/Layout";

import Destaque from "../../components/Destaque";
import Gallery from "../../components/Gallery";
import Arquetipo from "../../components/Arquetipo";
import Postitioning from "../../components/Positioning";
import Image from "../../components/Image";
import Downloads from "../../components/Downloads";
import Colors from "../../components/Colors";
import Video from "../../components/Video";
import RichText from "../../components/RichText";
import Slider from "../../components/Slider";

import { useGeneral } from "../../context/GeneralContext";

const Category = ({ match }) => {
  const { id } = match.params;
  const { handleCurrentCategory } = useGeneral();
  const [category, setCategory] = useState([]);
  //const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      await api
        .get(`/categories/${id}`)
        .then((resp) => {
          setCategory(resp.data);
        })
        .catch((err) => {});
      // await api
      //   .get(`articles/category/${id}`)
      //   .then((resp) => {
      //     setArticles(resp.data);
      //   })
      //   .catch((err) => {});
    };

    if (id) {
      getArticles();
      handleCurrentCategory(id);
    }
  }, [id]);

  return (
    <Layout>
      <div className="category">
        {category && (
          <Header>
            <h3>
              {category.title}
              {category.subtitle ? <span>{category.subtitle}</span> : <></>}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: category.description }} />
            <hr />
          </Header>
        )}
        {category && category.quickmenu_title && (
          <QuickTitle>{category.quickmenu_title}</QuickTitle>
        )}
        {category &&
          category.quickmenu &&
          category.subCategories &&
          category.subCategories.length > 0 && (
            <QuickMenu>
              {category.subCategories.map((item, index) => {
                return (
                  <QuickMenuItem
                    key={index}
                    bg={
                      item.image && item.image.url
                        ? `${defaultConfig.baseApi}${item.image.url}`
                        : ""
                    }
                  >
                    <Link to={`/category/${item.id}`}>{item.name}</Link>
                  </QuickMenuItem>
                );
              })}
            </QuickMenu>
          )}
        <Content>
          {category.Content &&
            category.Content.length > 0 &&
            category.Content.map((item, index) => {
              if (item.__component === "imagem.slider") {
                return (
                  <Slider
                    key={index}
                    position={item.position}
                    slides={item.slide}
                  />
                );
              }

              if (item.__component === "dados.destaque") {
                return (
                  <Destaque
                    key={index}
                    topTitle={item.toptitle}
                    title={item.title}
                    content={item.content}
                  />
                );
              }

              if (item.__component === "imagem.galery") {
                return (
                  <Gallery
                    key={index}
                    title={item.title}
                    collumns={item.columns}
                    images={item.gallery_item}
                  />
                );
              }

              if (item.__component === "dados.arquetipo") {
                return (
                  <Arquetipo
                    key={index}
                    image={item.image}
                    image_subtitle={item.image_subtitle}
                    title={item.title}
                    subtitle={item.subtitle}
                    content={item.content}
                  />
                );
              }

              if (item.__component === "dados.positions") {
                return (
                  <Postitioning
                    key={index}
                    title={item.title}
                    image={item.image || false}
                    data={item.position_items}
                  />
                );
              }

              if (item.__component === "imagem.image") {
                return (
                  <Image
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    image={item.image || false}
                    download_link={item.download_link}
                  />
                );
              }

              if (item.__component === "dados.download-list") {
                return <Downloads key={index} links={item.Links} />;
              }

              if (item.__component === "dados.video") {
                return <Video key={index} file={item.file} url={item.url} />;
              }

              if (item.__component === "dados.rich-text") {
                return (
                  <RichText
                    key={index}
                    content={item.content}
                    columns={item.columns}
                  />
                );
              }

              if (item.__component === "color.paleta") {
                return (
                  <Colors key={index} title={item.title} colors={item.colors} />
                );
              }
            })}
        </Content>
      </div>
    </Layout>
  );
};

export default Category;
