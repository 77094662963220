import styled from "styled-components";

import defaultConfig from "../../config/default";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  flex-direction: row;

  div.content {
    font-family: "Graphik";
    display: flex;
    flex-direction: column;
    max-width: 545px;
    width: 100%;
    font-size: 20px;

    span {
      font-family: "GraphikMedium";
      color: ${defaultConfig.colors.primary};
      margin-bottom: 10px;
    }

    h4 {
      font-size: 72px;
      font-family: "Graphik300";
      color: ${defaultConfig.colors.titles};
      margin-bottom: 10px;
    }

    div.text {
      font-family: "Graphik";
      font-size: 20px;
      line-height: 26px;
    }
  }

  div.image {
    margin: 0 14px 0 0;
    display: flex;
    align-self: baseline;
    position: relative;
    max-width: 527px;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    img {
      max-width: 850px;
      border-radius: 5px;
      width: 100%;
      margin: 0px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: rgb(0, 0, 0, 0.5);
      width: 100%;
      bottom: 0;
      color: #ffffff;
      height: 134px;
    }
  }
`;
