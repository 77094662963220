import React from "react";

import Layout from "../../components/Layout";

import { useBrand } from "../../context/BrandContext";

import Manifest from "../../components/Manifest";
import QuickMenu from "../../components/QuickMenu";

//import Carousel from "../../components/Carousel";

import Slider from "../../components/Slider";

import { SlideContainer } from "./styles";

const Main = () => {
  const { brand } = useBrand();

  //console.log(brand);

  return (
    <Layout marginStage={false} menu={false}>
      <Manifest />
      {brand && brand.slide && (
        <SlideContainer>
          <Slider position={brand.slide.position} slides={brand.slide.slide} />
        </SlideContainer>
      )}
      <QuickMenu />
    </Layout>
  );
};

export default Main;
