import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  width: 100%;
  white-space: pre-line;
  column-count: ${(props) => (props.columns === "duas" ? 2 : 1)};
  column-gap: 150px;
  font-size: 20px;
  line-height: 26px;
  font-family: "Graphik300";
  margin-bottom: 50px;

  @media ${devices.mobileXL} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileL} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileM} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileS} {
    font-size: 16px;
    line-height: 24px;
  }
`;
