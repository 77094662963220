import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

//import defaultConfig from "../../config/default";

import api from "../../services/api";

import { IoIosArrowDown , IoIosArrowUp} from "react-icons/io";

import { Container, Menu } from "./styles";

import { useAuth } from "../../context/AuthContext";

import { useGeneral } from "../../context/GeneralContext";

import { useBrand } from "../../context/BrandContext";

import { useLocation } from 'react-router-dom'

const Sidebar = ({ withMenu }) => {
  const [categories, setCategories] = useState([]);
  const { signOut } = useAuth();
  const { brand } = useBrand();

  const { sidebar, currentCategory, toogleMenu } = useGeneral();

  const location = useLocation();
  const isHome = location.pathname === '/';

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const getCategories = async () => {
      await api
        .get(`categories/brand/${brand.id}`)
        .then((resp) => {
          setCategories(resp.data);
        })
        .catch((err) => { });
    };

    if (brand) {
      getCategories();
    }
  }, [brand]);



  return (
    <Container withMenu={withMenu} className={sidebar ? "active" : ""}>
      {categories && categories.length > 0 && (
        <Menu className={isHome ? " menu isHome" : "menu"}>
          <li>
            <Link to="/" onClick={toogleMenu}>Home</Link>
          </li>
          {categories &&
            categories.map((item, index) => {

              return (
                <div key={index} className="divGroup">
                    <li onClick={toogleMenu}>
                  <Link
                    to={`/category/${item.id}`}
                    className={`${currentCategory == item.id ? "current" : ""} ${currentCategory == item.active ? "2" : ""}`}
                  >
                    {item.name}{" "}
                  </Link>

                  {item.subCategories && (
                    <ul className={`${ activeIndex == index ? "" : "colapsed"}`} >
                      {item.subCategories.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/category/${item.id}`}
                              className={`${currentCategory == item.id ? "current" : ""}`}
                            >
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
                <span className={`${item.subCategories.length == 0 ? "hide" : ""}`} >
                    {activeIndex == index  ? 
                       <IoIosArrowUp  className="custom-toggler navbar-toggler" 
                       data-toggle="collapse"
                       onClick={
                         event => {setActiveIndex(
                           activeIndex === index ? null : index
                        );

                       }}
                       data-target="#collapseExample"
                       aria-expanded="false"
                       aria-controls="collapseExample"/> : <IoIosArrowDown className="custom-toggler navbar-toggler" 
                       data-toggle="collapse"
                       onClick={event => setActiveIndex(
                           activeIndex === index ? null : index
                       )}
                       data-target="#collapseExample"
                       aria-expanded="false"
                       aria-controls="collapseExample"
                      /> 
                    }
                  </span>
                </div>
              );
            })}
          <li className="logout">
            <a href="#logout" onClick={signOut}>
              Sair
            </a>
          </li>
        </Menu>
      )}
    </Container>

     
   
  );
};

export default Sidebar;
