import React from "react";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Global from "./styles/global";
import Routes from "./routes";

import AppProvider from "./context";

//import Loader from "./components/Loader";

function App() {
  return (
    <>
      <Global />
      {/* <Loader /> */}
      <ToastContainer />
      <AppProvider>
        <Routes />
      </AppProvider>
    </>
  );
}

export default App;
