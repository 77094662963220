import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";

import api from "../services/api";

const BrandContext = createContext({});

const BrandProvider = ({ children }) => {
  const [data, setData] = useState({});

  const getBrand = useCallback(async () => {
    var slug = window.location.host.split(".")[1]
      ? window.location.host.split(".")[0]
      : false;

    await api
      .get(`/brands/slug/${slug}`)
      .then((resp) => {
        setData({ brand: resp.data });
      })
      .catch((err) => {
        setData({});
      });
  }, []);

  useEffect(() => {
    getBrand();
  }, [getBrand]);

  return (
    <BrandContext.Provider value={{ brand: data.brand }}>
      {children}
    </BrandContext.Provider>
  );
};

const useBrand = () => {
  const context = useContext(BrandContext);

  if (!context) {
    throw new Error("useAuth deve ser usado dentro de AuthContext");
  }

  return context;
};

export { BrandProvider, useBrand };
