import React from "react";

import { Container, Items, Item } from "./styles";

const Colors = ({ title, data, colors }) => {
  return (
    <>
      <Container>
        <h4>{title}</h4>
        <Items>
          {colors &&
            colors.map((color, index) => {
              return (
                <Item key={index} color={color}>
                  <div className="back"></div>
                  <div className="content">
                    <p className="title">
                      <strong>{color.name}</strong>
                    </p>
                    <p>
                      <span>HEX: </span>
                      {color.hex}
                    </p>
                    {color.pantone_c && (
                      <p>
                        <span>Pantone C: </span>
                        {color.pantone_c}
                      </p>
                    )}
                    {color.pantone_u && (
                      <p>
                        <span>Pantone U: </span>
                        {color.pantone_u}
                      </p>
                    )}
                    {color.cmyk && (
                      <p>
                        <span>CMYK: </span>
                        {color.cmyk}
                      </p>
                    )}
                    {color.rgb && (
                      <p>
                        <span>RGB: </span>
                        {color.rgb}
                      </p>
                    )}
                  </div>
                </Item>
              );
            })}
        </Items>
      </Container>
    </>
  );
};

export default Colors;
