import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0 160px;

  @media ${devices.tablet} {
    margin-bottom: 60px;
  }

  h4 {
    color: #00bfb3;
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 20px;
    font-family: "GraphikMedium";
  }

  .content {
    display: flex;
    flex-direction: ${(props) => (props.withImage ? "row" : "column")};
    justify-content: space-between;

    @media ${devices.tablet} {
      flex-direction: column;
    }
  }
`;

export const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.withImage ? 1 : 2)},
    minmax(100px, 1fr)
  );
  grid-gap: 30px 100px;
  width: ${(props) => (props.withImage ? "50%" : "100%")};

  @media ${devices.tablet} {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  font-size: 20px;
  max-width: 100%;
  overflow-wrap: break-word;
  font-family: "Graphik";
  line-height: 26px;

  @media ${devices.laptop} {
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.tablet} {
    max-width: 100%;
  }

  
  @media ${devices.mobileXL} {
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileL} {
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileM} {
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 24px;
  }

  @media ${devices.mobileS} {
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 24px;
  }

  span {
    color: #4a4a49;
    font-size: 72px;
    line-height: 70px;
    display: block;
    margin-bottom: 20px;
    font-family: "Graphik300";
    text-align: left;

    @media ${devices.desktop} { 
      font-family: "Graphik300";
      font-size: 38px;
      line-height: 38px;
      margin-bottom: 20px;
    }

    @media ${devices.laptopL} { 
      font-family: "Graphik300";
      font-size: 38px;
      line-height: 38px;
      margin-bottom: 20px;
    }


    @media ${devices.laptop} { 
      font-family: "Graphik300";
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    @media ${devices.tablet} {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 14px;
    }

    @media ${devices.mobileXL} {
      font-size: 32px;
      line-height: 32px;
    }

    @media ${devices.mobileL} {
      font-size: 32px;
      line-height: 32px;
    }

    @media ${devices.mobileM} {
      font-size: 32px;
      line-height: 32px;
    }

    @media ${devices.mobileS} {
      font-size: 32px;
      line-height: 32px;
    }
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 587px;
  padding-left: 20px;

  img {
    width: 100%;
  }

  @media ${devices.tablet} {
    margin-top: 30px;
  }
`;
