import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  .video {
    @media ${devices.mobileXL} {
      height: 220px !important;
    }

    @media ${devices.mobileL} {
      height: 200px !important;
    }

    @media ${devices.mobileM} {
      height: 180px !important;
    }

    @media ${devices.mobileS} {
      height: 150px !important;
    }

  }
`;
