const size = {
  mobileS: "321px",
  mobileM: "376px",
  mobileL: "426px",
  mobileXL: "631px",
  tablet: "769px",
  laptop: "1025px",
  laptopL: "1441px",
  desktop: "2561px",
  s: "576px",
  m: "768px",
  l: "992px",
  xl: "1200px",
};

const devices = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  s: `(min-width: ${size.s})`,
  m: `(min-width: ${size.m})`,
  l: `(min-width: ${size.l})`,
  xl: `(min-width: ${size.xl})`,
};

export default devices;
