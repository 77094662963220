import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
//import { useBrand } from "../context/BrandContext";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  // const { path } = rest;

  const { user } = useAuth();
  // const { brand } = useBrand();

  if (!user && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (user && !isPrivate) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaltProps = {
  isPrivate: false,
};
