import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
  h4 {
    margin-bottom: 15px;
    font-size: 42px;
    display: block;
    width: 100%;
    color: #4a4a49;
    margin-bottom: 70px;
  }
`;

export const Items = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 30%));
  grid-gap: 45px;
  list-style: none;
  width: 100%;

  @media ${devices.laptopL} {
    grid-template-columns: repeat(3, minmax(280px, 30%));
    grid-gap: 45;
  }


  @media ${devices.laptop} {
    grid-template-columns: repeat(3, minmax(280px, 30%));
    grid-gap: 45;
  }
  
  @media ${devices.tablet} {
    grid-template-columns: repeat(2, minmax(280px, 30%));
    grid-gap: 25px;
  }
  

  @media ${devices.mobileXL} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media ${devices.mobileL} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media ${devices.mobileM} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media ${devices.mobileS} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }


`;

export const Item = styled.li`
  width: 100%;
  box-shadow: 0 0 0.8em #777;
  background-color: #fff;
  border-radius: 4px;

  @media ${devices.mobileL} {
    width: 100%;
  }

  .back {
    background-color: ${(props) => (props.color.hex ? props.color.hex : "")};
    width: 100%;
    height: 166px;
    border-radius: 4px 4px 0 0;
  }

  .content {
    padding: 15px;

    p {
      display: flex;
      margin: 0 0 10px 0;
      font-size: 20px;
      min-width: initial;
      width: 100%;

      @media ${devices.desktop} {
        font-size: 24px;
      }

      
      @media ${devices.laptopL} {
        font-size: 18px;
      }

      
      @media ${devices.laptop} {
        font-size: 16px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
      }

      @media ${devices.mobileXL} {
        font-size: 16px;
      }

      @media ${devices.mobileL} {
        font-size: 16px;
      }

      @media ${devices.mobileM} {
        font-size: 16px;
      }

      @media ${devices.mobileS} {
        font-size: 16px;
      }

      &.title {
        font-size: 20px;
        display: flex;
        width: 100%;
        font-family: "GraphikBold";

        @media ${devices.laptopL} {
          font-size: 16px;
        }
      }


      span {
        display: block;
        width: 130px;
        margin-right: 20px;
        font-family: "GraphikMedium";
        font-size: 20px;
        min-width: 130px;

        @media ${devices.laptopL} {
          font-size: 16px;
          width: 130px;
          min-width: 130px;
        }

        @media ${devices.mobileXL} {
          font-size: 16px;
          width: 130px;
          min-width: 130px;
        }

        @media ${devices.mobileL} {
          font-size: 16px;
          width: 90px;
          min-width: 90px;
        }

        @media ${devices.mobileM} {
          font-size: 16px;
          width: 90px;
          min-width: 90px;
        }

        @media ${devices.mobileS} {
          font-size: 16px;
          width: 90px;
          min-width: 90px;
        }
      }
    }
  }
`;
