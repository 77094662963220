import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f6f8f9;
  padding: 80px 7%;
  justify-content: space-between;

  @media ${devices.laptop} {
    flex-direction: column;
    padding: 70px 7%;
  }

  @media ${devices.tablet} {
    flex-direction: column;
    padding: 60px 7%;
  }

  .title {
    margin: 0 40px 0 0;
    font-size: 20px;
    font-family: "Graphik300";

    @media ${devices.laptop} {
      margin: 0 0 35px 0;
    }

    @media ${devices.tablet} {
      margin: 0 0 5px 0;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(20px, 300px));
  grid-gap: 15px 25px;
  justify-self: flex-end;

  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
    grid-gap: 25px 25px;
  }

  @media ${devices.laptop} {
    grid-gap: 20px 20px;
  }

  @media ${devices.mobileL} {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
    grid-gap: 25px 25px;
  }
`;

export const MenuItem = styled.li`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${(props) => (props.bg ? props.bg : "")});
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  padding: 30px;
  height: 190px;
  width: 100%;
  max-width: 300px;
  position: relative;
  cursor: pointer;

  &::after {
    content: " ";
    position: absolute;
    background-color: #000;
    opacity: 0;
    transition: all 0.4s;
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &:hover {
    &::after {
      opacity: 0.5;
    }
  }

  @media ${devices.mobileL} {
    max-width: initial;
  }

  a {
    position: absolute;
    font-size: 20px;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 20px;

    &:hover {
      color: #fff;
    }
  }
`;
