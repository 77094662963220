import React, { useState } from "react";

import { GrClose } from "react-icons/gr";

import defaultConfig from "../../config/default";
import { Container } from "./styles";

import { useBrand } from "../../context/BrandContext";

const Manifest = () => {
  const { brand } = useBrand();

  const [played, setPlayed] = useState(false);
  const [muted, setMuted] = useState(true);

  const tooglePlay = (e) => {
    e.preventDefault();
    setPlayed(!played);
    setMuted(!muted);
  };

  return (
    <>
      {brand && (
        <Container
          className={played ? "played" : ""}
          bg={brand.manifest.image.url}
        >
          {brand.manifest && brand.manifest.video && (
            <>
              <a href="#close" className="close" onClick={(e) => tooglePlay(e)}>
                <GrClose size={30} color="#ffffff" />
              </a>
              <video autoPlay loop muted={muted} className="video">
                <source
                  src={`${defaultConfig.baseApi}${brand.manifest.video.url}`}
                  type="video/mp4"
                />
              </video>
            </>
          )}
          <div className="title">
            <h1>{brand.manifest.title}</h1>
            {brand.manifest.video && (
              <a href="#play" className="arrow" onClick={(e) => tooglePlay(e)}>
                Conheça nosso manifesto
              </a>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default Manifest;
