import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`

z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
  min-height: calc(100vh - 160px);
  padding: 30px 30px 0 20px;
  color: #fff;
  transition: all 0.4s;
  min-width: ${(props) => (props.withMenu ? "220px" : "0px")};
  padding: ${(props) => (props.withMenu ? "30px 30px 0 20px" : "0px")};
  position: ${(props) => (props.withMenu ? "initital" : "absolute")};
  width: ${(props) => (props.withMenu ? "20%" : "0px")};

  
  .menu.isHome {
    @media ${devices.desktopL} {
      display: none;
    }

    @media ${devices.desktop} {
      display: none;
    }

    @media ${devices.laptopL} {
      display: none;
    }

    @media ${devices.laptop} {
      display: initial;
    }
  }
  
  @media ${devices.desktopL} {
    height: 100%;
    margin-top: 100px;
    position: fixed;
  }

  @media ${devices.desktop} {
    height: 100%;
    margin-top: 100px;
    position: fixed;
  }

  @media ${devices.laptopL} {
    height: 100%;
    margin-top: 100px;
    position: fixed;
  }

  @media ${devices.laptop} {
    left: 0;
    position: absolute;
    width: 0px;
    overflow: hidden;
    padding: 0px;
    height: 100%;
    z-index: 100000;
    min-width: 0px;

    &.active {
      width: 220px;
      padding: 10px;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 50px 0 0 34%;


  @media ${devices.laptop} {
    margin: 113px 0 0 20px;
    min-width: 190px;
  }

  .divGroup {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .custom-toggler {
      @media ${devices.desktop} {
        width: 24px;
        height: 24px;
      }

      @media ${devices.laptopL} {
        width: 18px;
        height: 18px;
      }

      @media ${devices.laptop} {
        width: 18px;
        height: 18px;
      }

      @media ${devices.tablet} {
        width: 18px;
        height: 18px;
      }


      @media ${devices.mobileXL} {
        width: 18px;
        height: 18px;
      }

      @media ${devices.mobileL} {
        width: 16px;
        height: 16px;
      }


       
      }
  }

  .hide {
    display: none;
  }

  .colapsed {
    display: none;
  }

  li {


    margin-bottom: 10px;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      text-decoration: none;
      justify-content: space-between;
      font-size: 0.9vw;
      font-family: "Graphik300";

      &:hover {
        color: #00bfb3;
      }

      &.current {
        font-family: "GraphikMedium";
        border-left: 5px solid #00beb2;
        padding-left: 10px;
      }

      @media ${devices.laptop} {
        font-size: 14px;
      }

      @media ${devices.laptopL} {
        font-size: 14px;
      }

      svg {
        margin: 0 10px 0 0px;
      }
    }

    &.logout {
      margin-top: 40px;
    }

    ul {
      list-style: none;
      padding: 0 0 0 10px;
      margin: 8px 0 0 0;
    }
  }
`;
