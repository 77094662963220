import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
  font-family: "GraphikSemiboldItalic";
  src: url("/assets/fonts/Graphik-SemiboldItalic.eot");
  src: url("/assets/fonts/Graphik-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-SemiboldItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikRegularItalic";
  src: url("/assets/fonts/Graphik-RegularItalic.eot");
  src: url("/assets/fonts/Graphik-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-RegularItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/assets/fonts/Graphik-Regular.eot");
  src: url("/assets/fonts/Graphik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-Regular.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikLightItalic";
  src: url("/assets/fonts/Graphik-LightItalic.eot");
  src: url("/assets/fonts/Graphik-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikExtralightItalic";
  src: url("/assets/fonts/Graphik-ExtralightItalic.eot");
  src: url("/assets/fonts/Graphik-ExtralightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-ExtralightItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-ExtralightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikThin";
  src: url("/assets/fonts/Graphik-Thin.eot");
  src: url("/assets/fonts/Graphik-Thin.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Thin.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikThinItalic";
  src: url("/assets/fonts/Graphik-ThinItalic.eot");
  src: url("/assets/fonts/Graphik-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-ThinItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBold";
  src: url("/assets/fonts/Graphik-Bold.eot");
  src: url("/assets/fonts/Graphik-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Bold.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSuper";
  src: url("/assets/fonts/Graphik-Super.eot");
  src: url("/assets/fonts/Graphik-Super.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Super.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Super.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik300";
  src: url("/assets/fonts/Graphik-Light.eot");
  src: url("/assets/fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Light.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikMediumItalic";
  src: url("/assets/fonts/Graphik-MediumItalic.eot");
  src: url("/assets/fonts/Graphik-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-MediumItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikExtralight";
  src: url("/assets/fonts/Graphik-Extralight.eot");
  src: url("/assets/fonts/Graphik-Extralight.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-Extralight.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Extralight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBlack";
  src: url("/assets/fonts/Graphik-Black.eot");
  src: url("/assets/fonts/Graphik-Black.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Black.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikMedium";
  src: url("/assets/fonts/Graphik-Medium.eot");
  src: url("/assets/fonts/Graphik-Medium.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Graphik-Medium.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSemibold";
  src: url("/assets/fonts/Graphik-Semibold.eot");
  src: url("/assets/fonts/Graphik-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-Semibold.woff2") format("woff2"),
    url("/assets/fonts/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikSuperItalic";
  src: url("/assets/fonts/Graphik-SuperItalic.eot");
  src: url("/assets/fonts/Graphik-SuperItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-SuperItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-SuperItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBoldItalic";
  src: url("/assets/fonts/Graphik-BoldItalic.eot");
  src: url("/assets/fonts/Graphik-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "GraphikBlackItalic";
  src: url("/assets/fonts/Graphik-BlackItalic.eot");
  src: url("/assets/fonts/Graphik-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Graphik-BlackItalic.woff2") format("woff2"),
    url("/assets/fonts/Graphik-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}


  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-weight: normal;
  }

  html, body, #root{
    min-height: 100%;
  }

  body{
    background: #ffffff;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-size: 14px;
    font-family: "Graphik",sans-serif;
  }

  button{
    cursor: pointer;
  }

  p{
    margin-bottom: 25px;
  }

  .arrow{
    position: relative;
    transition: all 0.5s cubic-bezier(0,.95,.42,.94);
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    border: none;

    &:hover{
      &::before{
        width: 80%;
      }

      &::after{
        left: 78%;
      }
    }

    &::before{
      content: '';
      background: #fff;
      height: 1px;
      width: 60%;
      position: absolute;
      bottom: 8px;
      transition: all 0.5s cubic-bezier(0,.95,.42,.94);
    }

    &::after{
      content: '';
      background-image: url(https://enredo.com.br/wp-content/themes/enredo_v2/images/arrow-right-white.svg);
      width: 6px;
      height: 15px;
      position: absolute;
      left: 58%;
      bottom: 0px;
      background-repeat: no-repeat;
      transition: all 0.5s cubic-bezier(0,.95,.42,.94);
    }
  }
`;
