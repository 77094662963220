import React from "react";

import DownloadBtn from "../DownloadBtn";

import { Container } from "./styled";

const Downloads = ({ links }) => {
  return (
    <Container>
      {links &&
        links.map((item, idx) => {
          return <DownloadBtn key={idx} title={item.name} target={item.link} />;
        })}
    </Container>
  );
};

export default Downloads;
