import React from "react";

import ReactPlayer from "react-player";

import { Container } from "./styles";

const Video = ({ file, url }) => {
  return (
    <Container>
      {url && <ReactPlayer url={url} width={"100%"} controls={true} className="video"/>}
    </Container>
  );
};

export default Video;
