import styled from "styled-components";

import devices from "../../styles/devices";

export const SlideContainer = styled.div`
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 145px;
  padding-top: 145px;
  min-height: 200px;
  
  @media ${devices.mobileXL} {
    padding-bottom: 30px;
  }
  @media ${devices.mobileL} {
    padding-bottom: 30px;
  }
  @media ${devices.mobileM} {
    padding-bottom: 30px;
  }
  @media ${devices.mobileS} {
    padding-bottom: 30px;
  }
`;
