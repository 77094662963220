import React from "react";

import defaultConfig from "../../config/default";

import { Container, Image, Title } from "./styles";

const Gallery = ({ collumns, images, title }) => {
  return (
    <>
      {title && (
        <Title>
          <h3>{title}</h3>
        </Title>
      )}
      <Container collumns={collumns || 2}>
        {images &&
          images.map((image, index) => {
            return (
              <Image widhImage={image.image ? true : false} key={index}>
                {image.image && (
                  <img
                    src={`${defaultConfig.baseApi}${image.image.url}`}
                    alt={image.title}
                  />
                )}
                {!image.image && (
                  <div
                    className={`${
                      !image.image && index % 2 !== 0 ? "" : "right"
                    } title`}
                  >
                    {image.title}
                  </div>
                )}
                {image.subtitle && !image.image && (
                  <span
                    className={`withoutImage ${
                      !image.image && index % 2 !== 0 ? "" : "right"
                    }`}
                  >
                    {image.subtitle}
                  </span>
                )}
                {image.title && image.image && <h4>{image.title}</h4>}
                {image.subtitle && image.image && <span>{image.subtitle}</span>}
              </Image>
            );
          })}
      </Container>
    </>
  );
};

export default Gallery;
