import React from "react";

import defaultConfig from "../../config/default";

import { Container } from "./styles";

const Arquetipo = ({ image, image_subtitle, title, subtitle, content }) => {
  return (
    <Container>
      {image && (
        <div className="image">
          <img
            src={`${defaultConfig.baseApi}${image.url}`}
            alt={image_subtitle || title}
          />
          {image_subtitle && <span>{image_subtitle}</span>}
        </div>
      )}
      <div className="content">
        {subtitle && <span>{subtitle}</span>}
        {title && <h4>{title}</h4>}
        {content && (
          <div className="text" dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
    </Container>
  );
};

export default Arquetipo;
