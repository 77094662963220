import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.a`
  display: grid;
  grid-template-columns: minmax(50px, 50px) auto;
  align-items: center;
  background-color: #dddddd;
  color: #4a4a49;
  padding: 20px 100px 20px 44px;
  margin: 5px 0;
  text-decoration: none;
  font-size: 32px;
  height: 104px;
  width: 100%;

  @media ${devices.desktop} {
    font-size: 32px;
    border-radius: 30px;
  }

  @media ${devices.laptopL} {
    font-size: 32px;
    border-radius: 30px;
  }

  @media ${devices.laptop} {
    font-size: 26px;
    border-radius: 20px;
  }

  @media ${devices.tablet} {
    font-size: 22px;
    border-radius: 20px;
  }

  @media ${devices.mobileXL} {
    font-size: 20px;
    border-radius: 10px;
  }

  @media ${devices.mobileL} {
    font-size: 20px;
    border-radius: 10px;
  }

  @media ${devices.mobileM} {
    font-size: 20px;
    border-radius: 10px;
  }

  @media ${devices.mobileS} {
    font-size: 20px;
    border-radius: 10px;
  }

  svg {
    margin: 0 33px 0 0;
    color: #4a4a49;
    background-size: 20px 20px;
  }

  &:hover {
    background-color: #4a4a49;
    color: #fff;
    svg {
      color: #fff;
    }
  }
`;
