import styled from "styled-components";

import defaultConfig from "../../config/default";
import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  background-color: transparent;
  align-items: center;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
  background-image: ${(props) =>
    props.bg ? `url(${defaultConfig.baseApi}${props.bg})` : ""};
  background-position: center;
  background-size: cover;

  &.played {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .title {
      display: none;
    }

    .close {
      display: initial;
    }
    .video {
      width: 100%;
      height: AUTO;
    }
  }

  .close {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }

  .title {
    display: flex;
    position: absolute;
    z-index: 2;
    flex-direction: column;
    min-height: 80px;
    max-width: 40%;
    padding: 0 0 0 7%;
    justify-content: center;

    @media ${devices.mobileL} {
      padding: 0 0 0 25px;
      max-width: 100%;
    }

    h1 {
      font-family: "GraphikSemibold";
      font-size: 72px;
      


      @media ${devices.laptopL} {
        font-size: 60px;
      }

      @media ${devices.laptop} {
        font-size: 50px
      }

      @media ${devices.tablet} {
        font-size: 50px;
        max-width: 60px;
        line-height: 50px;
      }

      @media ${devices.mobileM} {
        font-size: 40px;
        line-height: 45px;
        padding-right: 25px;
      }

      @media ${devices.mobileS} {
        font-size: 40px;
        line-height: 45px;
        padding-right: 25px;
      }

      @media ${devices.mobileL} {
        font-size: 40px;
        line-height: 45px;
        padding-right: 25px;
      }

      @media ${devices.mobileXL} {
        font-size: 40px;
        line-height: 45px;
        padding-right: 25px;
      }
    }

    a {
      font-family: "GraphikMedium";
      color: #ffffff;
      margin-top: 20px;
      height: 38px;
      font-size: 18px;

      @media ${devices.mobileL} {
        font-family: "GraphikSemibold";
        font-size: 32px;
      }
    }
  }
`;
