import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #ededed;
  padding: 30px 10px;
  justify-content: space-between;
  align-items: initial;
  flex-wrap: wrap;

  .footer {
    width: 100%;
    display: grid;
    padding: 0px 2%;

    @media ${devices.desktopL} {
      grid-template-columns: 1fr 200px;
    }

    @media ${devices.desktop} {
      grid-template-columns: 1fr 200px;
    }

    @media ${devices.laptopL} {
      grid-template-columns: 1fr 150px;
    }

    @media ${devices.laptop} {
      grid-template-columns: 1fr 200px;
    }

    @media ${devices.tablet} {
      grid-template-columns: 1fr 200px;
    }

    @media ${devices.mobileXL} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileL} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileM} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileS} {
      grid-template-columns: 1fr;
    }

    .logo {
     
      margin: auto auto;

      img {
        margin: 0px auto;

        @media ${devices.desktopL} {
        margin-right: 50px;
      }

      @media ${devices.desktop} {
        margin-right: 50px;
      }

      @media ${devices.laptopL} {
        margin-right: 40px;
      }

      @media ${devices.laptop} {
        margin-right: 40px;
      }

      @media ${devices.tablet} {
        margin-right: 30px;
      }

      @media ${devices.mobileXL} {
        margin-top: 30px;
      }

      @media ${devices.mobileL} {
        margin-top: 20px;
      }

      @media ${devices.mobileM} {
        margin-top: 15px;
      }

      @media ${devices.mobileS} {
        margin-top: 10px;
      }
      }
    }
  }

  .others {
    display: grid;
    grid-gap: 25px 25px;

    @media ${devices.desktopL} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media ${devices.desktop} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media ${devices.laptopL} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media ${devices.laptop} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${devices.tablet} {
      grid-template-columns: repeat(2, 1fr);
      
    }

    @media ${devices.mobileXL} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileL} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileM} {
      grid-template-columns: 1fr;
    }

    @media ${devices.mobileS} {
      grid-template-columns: 1fr;
    }
  }

`;

export const NextMenu = styled.div`
  display: flex;
  background-color: #4a4a49;
  height: 170px;
  justify-content: flex-end;
  a.next {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    width: 50%;
    background-color: #272726;
    height: 100%;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      font-size: 20px;
      flex-direction: column;
      /* min-width: 303px; */
      padding-bottom: 15px;
      position: relative;

      @media ${devices.mobileL} {
        min-width: initial;
        font-size: 16px;
        border-bottom: 0px;
        padding-bottom: 17px;
      }

      span {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    &:hover {
      background-color: #000000;
    }

    @media ${devices.mobileL} {
      width: 100%;
    }
  }
  @media ${devices.mobileL} {
    height: 95px;
  }
`;
