import styled from "styled-components";

import devices from "../../styles/devices";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3,
  h4 {
    display: flex;
    flex-direction: column;
    font-family: "GraphikBold";
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 60px;
    color: #4a4a49;
    overflow-wrap: break-word;

    @media ${devices.laptop} {
      font-size: 50px;
      line-height: 50px;
      margin-top: 40px;
    }

    @media ${devices.tablet} {
      font-size: 50px;
      line-height: 50px;
      margin-top: 40px;
    }

    @media ${devices.mobileXL} {
      font-size: 32px;
      line-height: 32px;
      margin-top: 50px;
    }

    @media ${devices.mobileL} {
      font-size: 32px;
      line-height: 32px;
      margin-top: 50px;
    }

    @media ${devices.mobileM} {
      font-size: 32px;
      line-height: 32px;
      margin-top: 50px;
    }

    @media ${devices.mobileS} {
      font-size: 32px;
      line-height: 32px;
      margin-top: 50px;
    }

    span {
      font-family: "GraphikMedium";
      display: block;
      width: 100%;
      color: #00beb2;
      font-size: 18px;
      text-transform: uppercase;
      margin-top: 10px;

      @media ${devices.laptop} {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }
      @media ${devices.mobileXL} {
        font-family: "GraphikMedium";
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 24px;
      }

      @media ${devices.mobileL} {
        font-family: "GraphikMedium";
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 24px;
      }

      @media ${devices.mobileM} {
        font-family: "GraphikMedium";
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 24px;
      }

      @media ${devices.mobileS} {
        font-family: "GraphikMedium";
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 24px;
      }
    }
  }

  h4 {
    font-size: 42px;
  }

  div {
    display: block;
    width: 50%;
    font-family: "Graphik300";
    font-size: 20px;
    margin-bottom: 10px;
    max-width: 788px;
    width: 100%;
    overflow-wrap: break-word;
    line-height: 30px;
    white-space: pre-line;

    @media ${devices.laptop} {
      max-width: 100%;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    @media ${devices.tablet} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.mobileXL} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.mobileL} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.mobileM} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.mobileS} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  hr {
    height: 1px;
    border-width: 0;
    color: #dadada;
    background-color: #dadada;

    @media ${devices.desktop} {
      margin: 40px 0 30px;
    }

    @media ${devices.laptopL} {
      margin: 40px 0 30px;
    }

    @media ${devices.laptop} {
      margin: 40px 0 30px;
    }

    @media ${devices.tablet} {
      margin: 40px 0 30px;
    }

    @media ${devices.mobileXL} {
      margin: 27px 0 35px;
    }

    @media ${devices.mobileL} {
      margin: 27px 0 35px;
    }

    @media ${devices.mobileM} {
      margin: 27px 0 35px;
    }

    @media ${devices.mobileS} {
      margin: 27px 0 35px;
    }
  }
`;

export const QuickTitle = styled.div`
  display: flex;
  color: #00bfb3;
  font-size: 20px;
  line-height: 26px;
  font-family: "GraphikMedium";
  margin-bottom: 30px;
`;

export const QuickMenu = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(20px, 300px));
  grid-gap: 25px 25px;
  justify-self: flex-end;

  @media ${devices.desktop} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.laptopL} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.laptop} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.tablet} {
    margin-bottom: 30px;
  }

  @media ${devices.mobileXL} {
    grid-gap: 25px 25px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.mobileL} {
    grid-gap: 25px 25px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.mobileM} {
    grid-gap: 25px 25px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }

  @media ${devices.mobileS} {
    grid-gap: 25px 25px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }
`;

export const QuickMenuItem = styled.li`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${(props) => (props.bg ? props.bg : "")});
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  padding: 30px;
  height: 190px;
  width: 100%;
  max-width: 100%;

  @media ${devices.mobileL} {
    max-width: initial;
  }
  a {
    font-size: 20px;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    mix-blend-mode: normal;
    &:hover {
      color: #fff;
    }

    @media ${devices.mobileXL} {
      color: #fff;
      font-weight: bold;
      mix-blend-mode: normal;
    }

    @media ${devices.mobileL} {
      color: #fff;
      font-weight: bold;
      mix-blend-mode: normal;
    }

    @media ${devices.mobileM} {
      color: #fff;
      font-weight: bold;
      mix-blend-mode: normal;
    }

    @media ${devices.mobileS} {
      color: #fff;
      font-weight: bold;
      mix-blend-mode: normal;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 1218px; */
`;
