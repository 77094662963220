import styled from "styled-components";

import devices from "../../styles/devices";

import defaultConfig from "../../config/default";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 100px;
  width: 100%;

  @media ${devices.mobileXL} {
    padding: 25px 0;
  }

  @media ${devices.mobileL} {
    padding: 25px 0;
  }

  @media ${devices.mobileM} {
    padding: 25px 0;
  }

  @media ${devices.mobileS} {
    padding: 25px 0;
  }

  span {
    font-family: "GraphikMedium";
    font-size: 20px;
    line-height: 26px;
    color: ${defaultConfig.colors.primary};
    margin-bottom: 25px;

    @media ${devices.laptop} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.tablet} {
      font-size: 16px;
      line-height: 24px;
    }

    @media ${devices.mobileXL} {
      font-size: 16px;
    }

    @media ${devices.mobileL} {
      font-size: 16px;
    }

    @media ${devices.mobileM} {
      font-size: 16px;
    }

    @media ${devices.mobileS} {
      font-size: 16px;
    }
  }

  h3 {
    font-family: "Graphik300";
    font-size: 72px;
    line-height: 70px;
    color: ${defaultConfig.colors.titles};
    text-align: left;
    margin-bottom: 89px;

    @media ${devices.laptopl} {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 60px;
    }

    @media ${devices.laptop} {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 50px;
    }

    @media ${devices.tablet} {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 50px;
    }

    @media ${devices.mobileXL} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    @media ${devices.mobileL} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    @media ${devices.mobileM} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    @media ${devices.mobileS} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 40px;
    }
  }

  div.content {
    font-family: "Graphik";
    font-size: 20px;
    line-height: 26px;
    max-width: 556px;
    width: 100%;

    p {
      @media ${devices.laptop} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.laptop} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.mobileXL} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.mobileL} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.mobileM} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      @media ${devices.mobileS} {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
      }
    }

    ul {
      margin: 0px 0px 0px 20px;
    }

    ul li {
      margin: 10px 0px;
      font-size: 16px;

      @media ${devices.desktop} {
        font-size: 18px;
        line-height: 24px;
      }


      @media ${devices.laptopL} {
        font-size: 18px;
        line-height: 24px;
      }

    }
  }
`;
