import styled from "styled-components";

import devices from "../../styles/devices";

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;

  h3 {
    display: flex;
    flex-direction: column;
    font-family: "GraphikBold";
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 28px;
    color: #4a4a49;
    overflow-wrap: break-word;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.collumns ? props.collumns : 1)},
    minmax(100px, ${(props) => (props.collumns > 1 ? "527px" : "1217px")})
  );
  grid-gap: 60px 20px;
  width: 100%;

  margin-bottom: 40px;

  @media ${devices.mobileXL} {
    grid-template-columns: 1fr;
    grid-gap: 20px 0;
  }
`;

export const SliderDiv = styled.div`
  display: block;
  position: relative;
  max-width: 100%;
`;
export const SliderItem = styled.div`
  color: #fff;
`;

export const Image = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.widhImage ? "end" : "")};

  img {
    width: 100%;
    border-radius: 5px;
  }

  .title {
    font-size: 72px;
    font-family: "Graphik300";
    line-height: 70px;
    color: #4a4a49;

    &.right {
      text-align: right;
    }

    @media ${devices.mobileXL} {
      font-size: 30px;
      line-height: 35px;

      &.right {
        text-align: left;
      }
    }
  }

  h4 {
    margin-top: 25px;
    font-size: 20px;
    font-family: "GraphikMedium";
    line-height: 26px;
  }

  @media ${devices.mobileXL} {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  span {
    display: block;
    margin: 15px 0;
    font-family: "Graphik300";
    font-size: 16px;
    line-height: 26px;

    @media ${devices.desktop} { 
    font-size: 36px;
  }

    @media ${devices.laptoL} { 
    font-size: 24px;
  }

  @media ${devices.laptop} { 
    font-size: 18px;
  }

  @media ${devices.tablet} { 
    font-size: 14px;
  }


  @media ${devices.mobileXL} { 
    font-size: 14px;
  }

  @media ${devices.mobileL} { 
    font-size: 14px;
  }

  @media ${devices.mobileM} { 
    font-size: 14px;
  }

  
  @media ${devices.mobileS} { 
    font-size: 14px;
  }

    &.right {
      text-align: right;
      @media ${devices.mobileXL} {
        text-align: left;
      }
    }

    &.withoutImage {
      font-size: 20px;
      color: "#707070";
    }
  }
`;
