import React from "react";

import defaultConfig from "../../config/default";

import { Container, Items, Item, Image } from "./styles";

const Positioning = ({ title, data, image }) => {
  return (
    <Container withImage={image}>
      <h4>{title}</h4>
      <div className="content">
        <Items withImage={image}>
          {data &&
            data.map((item, index) => (
              <Item key={index}>
                <span>{item.title}</span>
                {item.description}
              </Item>
            ))}
        </Items>
        {image && (
          <Image>
            <img
              src={`${defaultConfig.baseApi}${image.url}`}
              alt="Plataforma da Marca"
            />
          </Image>
        )}
      </div>
    </Container>
  );
};

export default Positioning;
