import React, { createContext, useCallback, useState, useContext } from "react";

const GeneralContext = createContext({});

const GeneralProvider = ({ children }) => {
  const [data, setData] = useState({
    sidebar: false,
    currentCategory: null,
  });

  const toogleMenu = useCallback(() => {
    const { sidebar } = data;
    setData({
      ...data,
      sidebar: !sidebar,
    });
  }, [data]);

  const handleCurrentCategory = useCallback(
    (current) => {
      setData({
        ...data,
        currentCategory: Number(current),
      });
    },
    [data]
  );

  return (
    <GeneralContext.Provider
      value={{
        toogleMenu,
        handleCurrentCategory,
        currentCategory: data.currentCategory,
        sidebar: data.sidebar,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

const useGeneral = () => {
  const context = useContext(GeneralContext);

  if (!context) {
    throw new Error("useGeneral deve ser usado dentro de GeneralContext");
  }

  return context;
};

export { GeneralProvider, useGeneral };
