import React from "react";

import { Container } from "./styled";

const RichText = ({ content, columns }) => {
  return (
    <Container
      dangerouslySetInnerHTML={{ __html: content }}
      columns={columns}
    />
  );
};

export default RichText;
