import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  position: relative;
  background-color: #000;
  color: #fff;
  min-height: 100px;
  align-items: stretch;
  padding: 0px 10px;
  justify-content: space-between;

  @media ${devices.desktopL} {
      position: fixed;
      width: 100%;
      z-index: 1000
  }

  @media ${devices.desktop} {
      position: fixed;
      width: 100%;
      z-index: 1000
  }

  @media ${devices.laptopL} {
      position: fixed;
      width: 100%;
      z-index: 1000
  }


  .logo {
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-left: 5%;
    img {
      max-height: 45px;
    }
  }

  .menu {
    display: none;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media ${devices.laptop} {
    justify-content: center;

    .logo {
      margin-left: 0px;
    }

    .menu {
      display: block;
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  color: #fff;
  list-style: none;
  align-self: stretch;
  margin-right: 20px;

  li {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 15px;
    border-bottom: 3px solid #000000;
    a {
      font-family: "Graphik300";
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      a {
        /* font-family: "GraphikMedium"; */
      }
      border-bottom: 3px solid #00beb2;
    }
  }

  a.active {
    border-bottom: 3px solid #00beb2;
  }

    

  @media ${devices.laptop} {
    display: none;
  }
`;
