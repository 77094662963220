import React from "react";

import { Link } from "react-router-dom";

import defaultConfig from "../../config/default";

import { Container, Menu, MenuItem } from "./styles";

import { useBrand } from "../../context/BrandContext";

const QuickMenu = () => {
  const { brand } = useBrand();

  return (
    <Container>
      <div className="title">ACESSO RÁPIDO</div>
      <Menu>
        {brand &&
          brand.quickmenu &&
          brand.quickmenu.map((item, index) => {
            return (
              <MenuItem
                key={index}
                bg={`${
                  item.image && item.image.url
                    ? `${defaultConfig.baseApi}${item.image.url}`
                    : false
                }`}
              >
                {item.category ? (
                  <Link to={`/category/${item.category.id}`}>
                    {item.category.name || "Sem Título"}
                  </Link>
                ) : (
                  <a href={item.link || "#"}>{item.name || "Sem Título"}</a>
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </Container>
  );
};

export default QuickMenu;
