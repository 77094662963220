import React from "react";

import Proptypes from "prop-types";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

import { Container, Content } from "./styles";

import { useLocation } from 'react-router-dom'

const Layout = ({ children, marginStage, menu }) => {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <Container>
      <Header sandwichMenu />
      <Sidebar withMenu={menu} />
      <Content margin={marginStage}>
        <div className={`stage ${!isHome ? "noIsHome" : ""}`}>{children}</div>
      </Content>
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  marginStage: Proptypes.bool,
  menu: Proptypes.bool,
};

Layout.defaultProps = {
  marginStage: true,
  menu: true,
};

export default Layout;
