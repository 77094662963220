import React from "react";

import { GrDownload } from "react-icons/gr";

import { Container } from "./styles";

const DownloadBtn = ({ title, target }) => {
  return (
    <Container className="header" href={target}>
      <div> <GrDownload /></div>
      <div> {title} </div>
    </Container>
  );
};

export default DownloadBtn;
