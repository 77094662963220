import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #ffffff;
  background: ${(props) => (props.bg ? `url(${props.bg})` : "#000000")};
  background-color: #000000;
  background-size: cover;
  background-repeat: no-repeat;

  video {
    display: flex;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0.6;
    @media ${devices.tablet} {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  padding: 10px 5%;

  header {
    width: 100%;
    margin: 117px 0 0 0;
    img {
      max-width: 250px;
    }
  }
`;

export const Form = styled.div`
  width: 100%;
  padding: 10% 0;
  h1 {
    font-family: "Graphik300";
    font-size: 55px;
    margin-bottom: 40px;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    @media ${devices.mobileL} {
      max-width: 100%;
    }

    input {
      background: transparent;
      border: 0;
      border-bottom: 1px solid #fff;
      margin-top: 20px;
      padding: 10px 5px;
      color: #ffffff;
    }

    span.error {
      color: #ff8181;
      padding: 10px 0;
    }

    a {
      color: #fff;
      margin: 15px 0 10px 0;
      &:hover {
        color: #00beb2;
      }
    }

    button {
      display: inline-block;
      background: transparent;
      border: 1px solid #00beb2;
      color: #00beb2;
      padding: 10px 20px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
      width: fit-content;

      &:hover {
        background-color: #00beb2;
        color: #fff;
      }
    }
  }
`;

export const Footer = styled.div`
  justify-self: flex-end;
`;
