import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .noIsHome {
   
    @media ${devices.desktopL} {
      margin-left: 20%;
      
    }

    @media ${devices.desktop} {
      margin-left: 20%;
    }

    @media ${devices.laptopL} {
        margin-left: 20%;
    }

    @media ${devices.laptop} {
      margin-left: 0%;
    }
  }

  @media ${devices.laptop} {
    position: relative;
  }

  .category {
    @media ${devices.laptop} {
      margin: 0px 35px;
    }

    @media ${devices.tablet} {
      margin: 0px 53px;
    }

    @media ${devices.mobileXL} {
      margin: 0px 5px;
    }

    @media ${devices.mobileL} {
      margin: 0px 5px;
    }

    @media ${devices.mobileM} {
      margin: 0px 5px;
    }

    @media ${devices.mobileS} {
      margin: 0px 5px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  /* max-width: 1920px; */
  position: relative;

  z-index: 0;

  .noIsHome {
   
    @media ${devices.desktopL} {
      margin-left: 22%;
    }

    @media ${devices.desktop} {
      margin-left: 22%;
    }

    @media ${devices.laptopL} {
        margin-left: 22%;
    }

    @media ${devices.laptop} {
      margin-left: 0%;
    }
  }



  @media ${devices.desktopL} {
    z-index: 900;
    margin-top: 100px;
  }

  @media ${devices.desktop} {
    z-index: 900;
    margin-top: 100px;
  }

  @media ${devices.laptopL} {
      z-index: 900;
      margin-top: 100px;
  }

  .stage {
    padding: ${(props) => (props.margin ? "70px 9.6% 30px 0%" : "0")};
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${devices.laptop} {
      padding: ${(props) => (props.margin ? "37px 20px 20px" : "0")};
      width: 100%;
    }

    @media ${devices.tablet} {
      padding: ${(props) => (props.margin ? "0 26px" : "0")};
      width: 100%;
    }
  }
`;
