import React from "react";
import { Container } from "./styles";

const Destaque = ({ topTitle, title, content }) => {
  return (
    <Container>
      {topTitle && <span>{topTitle}</span>}
      {title && <h3>{title}</h3>}
      {content && (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </Container>
  );
};

export default Destaque;
