import React from "react";

import defaultConfig from "../../config/default";

import { Container, Content, Download } from "./styles";

const Image = ({ title, subtitle, description, image, download_link }) => {
  return (
    <Container>
      {title && <h3>{title}</h3>}
      {description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {image && (
        <Content>
          <img src={`${defaultConfig.baseApi}${image.url}`} alt="" />
          {subtitle && <span>{subtitle}</span>}
        </Content>
      )}
      {download_link && <Download href={download_link}>Download</Download>}
    </Container>
  );
};

export default Image;
