import React from "react";
import Slider from "react-slick";

import defaultConfig from "../../config/default";
import { Container, Item } from "./style";

const SliderComponent = ({ position, slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container>
      <Slider {...settings}>
        {slides &&
          slides.map((item, idx) => {
            return (
              <div className="item">
                <Item key={idx} position={position}>
                  <div className="image">
                    {item && item.image && item.image.url ?
                      <img
                        src={`${defaultConfig.baseApi}/${item.image.url}`}
                        alt={item.title}
                      />
                      :
                      <div></div>
                    }
                  </div>
                  <div className="content">
                    <div className="text">{item.description}</div>
                    <div className="subtitle">{item.title}</div>
                  </div>
                </Item>
              </div>
            );
          })}
      </Slider>
    </Container>
  );
};

export default SliderComponent;
