import React, { createContext, useCallback, useState, useContext } from "react";

import { toast } from "react-toastify";

import api from "../services/api";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@Auth:token");
    const user = localStorage.getItem("@Auth:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    api
      .post("auth/local", {
        identifier: email,
        password: password,
      })
      .then((resp) => {
        const { jwt: token, user } = resp.data;

        localStorage.setItem("@Auth:token", token);
        localStorage.setItem("@Auth:user", JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });
      })
      .catch((err) => {
        toast.error("Usuário e/ou Senha estão incorretos!");
      });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@Auth:token");
    localStorage.removeItem("@Auth:user");

    setData({});
  }, []);

  const forgotPassword = useCallback((email) => {
    api
      .post("/auth/forgot-password", {
        email,
      })
      .then((resp) => {
        toast.success("Link para alterar senha foi enviado para seu e-mail!");
      })
      .catch((err) => {
        toast.error("E-mail não encontrado!");
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, forgotPassword, user: data.user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser usado dentro de AuthContext");
  }

  return context;
};

export { AuthProvider, useAuth };
