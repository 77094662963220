import React, { useEffect, useState, useCallback } from "react";
import { parseISO, format } from "date-fns";

import { Link } from "react-router-dom";

import { useBrand } from "../../context/BrandContext";
import { useGeneral } from "../../context/GeneralContext";

import api from "../../services/api";

import LogoRodape from "../../assets/images/logo_rodape.png";
import { Container, NextMenu } from "./styles";

import { useLocation } from 'react-router-dom'

const Footer = () => {
  const [next, setNext] = useState({});
  const [categories, setCategories] = useState([]);

  const { brand } = useBrand();
  const { currentCategory } = useGeneral();

  const location = useLocation();
  const isHome = location.pathname === '/';

  const orderCategories = useCallback((categories) => {
    let new_categories = [];

    if (categories) {
      categories.forEach((item) => {
        new_categories.push(item);

        if (item.subCategories) {
          item.subCategories.forEach((subItem) => {
            new_categories.push(subItem);
          });
        }
      });
    }

    setCategories(new_categories);
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      await api
        .get(`categories/brand/${brand.id}`)
        .then((resp) => {
          orderCategories(resp.data);
        })
        .catch((err) => { });
    };

    if (brand) {
      getCategories();
    }
  }, [brand, orderCategories]);

  useEffect(() => {
    const getNextCategory = () => {
      const current = currentCategory || null;
      let stopNext = false;
      categories.some((item) => {
        if (current === null) {
          setNext(item);
          return true;
        } else {
          if (stopNext) {
            setNext(item);
            return true;
          }
          if (current === item.id) stopNext = true;
        }
      });
    };

    getNextCategory();
  }, [categories, currentCategory]);

  return (
    <div className={` ${!isHome ? "noIsHome" : ""}`}>
      {next && next.id && (
        <NextMenu>
          <Link
            to={`/category/${next.id}`}
            onClick={window.scrollTo(0, 0)}
            className="next "
          >
            <div className="arrow">
              <span>Próximo</span>
              {next.name}
            </div>
          </Link>
        </NextMenu>
      )}
      <Container>
        <div className="footer">
          <div className="others">
            <div>
              © Universo de Marca
              <br />
              Enredo Brand Innovation
            </div>

            {brand?.last_update && (
              <div>
                Última atualização
                <br />
                {format(parseISO(brand.last_update), "dd/MM/yyyy hh:mm:ss")}
              </div>
            )}

            {brand?.contact && (
              <div>
                Dúvidas? <br />
                Fale com {brand.contact}
              </div>
            )}

            <div>Termos e Privacidade</div>
          </div>
          <div className="logo">
            <img src={LogoRodape} alt="Enredo" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
