import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import defaultConfig from "../../config/default";

import api from "../../services/api";

import { useAuth } from "../../context/AuthContext";
import { useBrand } from "../../context/BrandContext";

import { useGeneral } from "../../context/GeneralContext";

import { Container, Menu } from "./styles";

import { GiHamburgerMenu } from "react-icons/gi";

const Header = ({ sandwichMenu }) => {
  const [categories, setCategories] = useState([]);

  const { signOut } = useAuth();
  const { brand } = useBrand();
  const { toogleMenu } = useGeneral();

  const handleLougout = (e) => {
    e.preventDefault();
    signOut();
  };

  const handleMenu = (e) => {
    e.preventDefault();
    toogleMenu();
  };

  useEffect(() => {
    const getCategories = async () => {
      await api
        .get(`categories/brand/${brand.id}`)
        .then((resp) => {
          setCategories(resp.data);
        })
        .catch((err) => { });
    };

    if (brand) {
      getCategories();
    }
  }, [brand]);

  return (
    <Container>
      {sandwichMenu && (
        <a href="#ll" className="menu" onClick={handleMenu}>
          <GiHamburgerMenu color="#ffffff" size={25} />
        </a>
      )}
      { brand && brand.logo && brand.logo.url ?
        <a href="/" className="logo"  >
          <img
            src={`${defaultConfig.baseApi}${brand.logo.url}`}
            alt={brand.title}
          />
        </a>
        :
        <div></div>
      }

      <Menu>
        <li  >
          <NavLink exact={true} to='/' >Home</NavLink>
        </li>
        {categories.map((item, index) => {
          return (
            <li key={index} >
              <NavLink to={`/category/${item.id}`} >{item.name}</NavLink>
            </li>
          );
        })}
        <li>
          <a onClick={handleLougout} href="#logout">
            Sair
          </a>
        </li>
      </Menu>
    </Container>
  );
};

export default Header;
