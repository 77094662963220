import styled from "styled-components";

import defaultConfig from "../../config/default";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;

  h3 {
    display: flex;
    flex-direction: column;
    font-family: "GraphikBold";
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 28px;
    color: ${defaultConfig.colors.titles};
    overflow-wrap: break-word;
  }

  div.description {
    display: block;
    max-width: 788px;
    font-family: "Graphik300";
    font-size: 20px;
    margin-bottom: 58px;
    width: 100%;
    overflow-wrap: break-word;
    line-height: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
  }
`;

export const Download = styled.div``;
