import styled from "styled-components";

import devices from "../../styles/devices";

export const Container = styled.div`
  display: block;
  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }

  /* display: flex;
  width: 100%;
  position: relative;
  padding: 30px 0 80px;
  justify-content: center;
  align-items: center;

  > div {
    display: initial;
    position: relative;
    width: 100%;
    max-width: 2210px; */

  /* @media ${devices.laptopL} {
      max-width: 1430px;
    }

    @media ${devices.tablet} {
      max-width: 660px;
    } */
  /* } */
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  min-height: 200px;
  margin-right: 10px;

  @media ${devices.mobileXL} {
    flex-direction: column;
  }

  .image {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    order: ${(props) => (props.position === "left" ? 1 : 3)};

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    @media ${devices.mobileXL} {
      width: 100%;
      margin-bottom: 20px;
    }

    @media ${devices.laptop} {
      width: 60%;
      padding-top: 90px;
      padding-bottom: 90px;
    }

    @media ${devices.tablet} {
      width: 60%;
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: space-between;
    order: 2;
    margin: ${(props) => (props.position === "left" ? "0" : "0 160px 0 0")};

    @media ${devices.laptop} { 
      margin: ${(props) => (props.position === "left" ? "0" : "0 50px 0 0")};
    }

    @media ${devices.tablet} {
      margin: ${(props) => (props.position === "left" ? "0" : "0 50px 0 0")};
    }

    @media ${devices.mobileXL} {
      width: 100%;
      margin: 15px 0;
    }

    .text {
      font-family: ${(props) =>
        props.position === "left" ? "GraphikLight" : "Graphik300"};
      font-size: ${(props) => (props.position === "left" ? "30px" : "72px")};
      line-height: ${(props) => (props.position === "left" ? "35px" : "72px")};
      margin-bottom: 40px;
      /* font-size: 1.8vw;
      line-height: 1.8vw; */
    

      @media ${devices.laptopL} {
        font-size: ${(props) => (props.position === "left" ? "30px" : "50px")};
        line-height: ${(props) =>
          props.position === "left" ? "35px" : "50px"};
      }

      @media ${devices.laptop} {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 30px;
      }

      @media ${devices.tablet} {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 30px;
      }

      @media ${devices.mobileXL} {
        font-size: 35px;
        line-height: 42px;
      }

      @media ${devices.mobileL} {
        font-size: 35px;
        line-height: 42px;
      }

      @media ${devices.mobileM} {
        font-size: 35px;
        line-height: 42px;
      }

      @media ${devices.mobileS} {
        font-size: 35px;
        line-height: 42px;
      }
    }

    .subtitle {
      font-family: "Graphik";
      font-size: 20px;
      line-height: 26px;

      @media ${devices.laptopL} {
        font-size: 15px;
        line-height: 24px;
      }

      @media ${devices.tablet} {
        font-size: 15px;
        line-height: 21px;
      }

      @media ${devices.mobileXL} {
        font-size: 16px;
        line-height: 24px;
      }

      @media ${devices.mobileL} {
        font-size: 16px;
        line-height: 24px;
      }

      @media ${devices.mobileM} {
        font-size: 16px;
        line-height: 24px;
      }

      @media ${devices.mobileS} {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;
