import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import Route from "./Route";

import Main from "../pages/Main";
import Category from "../pages/Category";
import Login from "../pages/Login";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Main} isPrivate />

        <Route path="/category/:id" exact component={Category} isPrivate />

        <Route path="/login" exact component={Login} />

        <Route path="/nobrand" exact component={() => <h1>Error 404</h1>} />

        <Route path="/" component={() => <h1>Error 404</h1>} />
      </Switch>
    </BrowserRouter>
  );
}
