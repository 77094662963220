import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik, Form as FormikForm, Field } from "formik";

import defaultConfig from "../../config/default";

import { Container, Content, Form, Footer } from "./styles";

import { useAuth } from "../../context/AuthContext";
import { useBrand } from "../../context/BrandContext";

const Login = () => {
  const [forgot, setForgot] = useState(false);
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Insira um e-mail válido")
      .required("E-mail é obrigatório"),
    password: Yup.string().required("Senha é obrigatório"),
  });

  const formForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email("Insira um e-mail válido!")
      .required("E-mail é obrigatório!"),
  });

  const { signIn, forgotPassword } = useAuth();
  const { brand } = useBrand();

  //console.log(brand);

  const handleSubmit = async (data) => {
    signIn(data);
  };

  const handleForgotPassword = async (data) => {
    forgotPassword(data.email);
  };

  return (
    <Container
      bg={`${
        brand && brand.background
          ? `${defaultConfig.baseApi}${brand.background.url}`
          : null
      }`}
    >
      {brand && brand.video && (
        <video autoPlay loop muted>
          <source
            src={`${defaultConfig.baseApi}${brand.video.url}`}
            type="video/mp4"
          />
        </video>
      )}

      <Content>
        <header>
          <img
            src={`${
              brand && brand.logo
                ? `${defaultConfig.baseApi}${brand.logo.url}`
                : null
            }`}
            alt={brand && brand.title}
          />
        </header>
        <Form>
          <h1>
            Universo
            <br />
            da marca
          </h1>
          {!forgot && (
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
              validationSchema={formSchema}
            >
              <FormikForm>
                <Field name="email" placeholder="E-mail" type="text" />
                <ErrorMessage className="error" component="span" name="email" />
                <Field name="password" placeholder="Senha" type="password" />
                <ErrorMessage
                  className="error"
                  component="span"
                  name="password"
                />
                <a
                  href="#forgot"
                  onClick={(e) => {
                    e.preventDefault();
                    setForgot(true);
                  }}
                >
                  esqueci a senha
                </a>
                <button type="submit">Entrar</button>
              </FormikForm>
            </Formik>
          )}
          {forgot && (
            <Formik
              initialValues={{ email: "" }}
              onSubmit={handleForgotPassword}
              validationSchema={formForgotSchema}
            >
              <FormikForm>
                <Field name="email" placeholder="E-mail" type="text" />
                <ErrorMessage className="error" component="span" name="email" />
                <button type="submit">Recuperar Senha</button>
              </FormikForm>
            </Formik>
          )}
        </Form>
        <Footer>© Enredo Brand Innovation</Footer>
      </Content>
    </Container>
  );
};

export default Login;
