import React from "react";

import { AuthProvider } from "./AuthContext";
import { BrandProvider } from "./BrandContext";
import { GeneralProvider } from "./GeneralContext";

const AppProvider = ({ children }) => (
  <BrandProvider>
    <GeneralProvider>
      <AuthProvider>{children}</AuthProvider>
    </GeneralProvider>
  </BrandProvider>
);

export default AppProvider;
